import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    background: "white",
    width: "70%",
    height: "85%",
    inset: "",
    overflowY: "auto",
    position: "relative",
    border: "1px solid #ccc",
    borderRadius: "10px",
  },
};

const buttonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
};

export const EgitimModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
        onClick={() => setIsOpen(true)}
      >
        {"> "}
        {t("details")}
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById("site-main")}
      >
        <button onClick={() => setIsOpen(false)} style={buttonStyles}>
          X
        </button>
        <div className="ttm-service-description mt-20">
          <h3 className="mb-20">{t("training_popup_header")}</h3>
          <p>{t("training_popup_text")}</p>

          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

          <p>
            <b>{t("training_time")}:</b>  {t("days")}
          </p>
          <p>
            <b>{t("training_type")}:</b>  {t("type")}
          </p>
          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
          <h5 className="mb-20">{t("training_subject")}</h5>
          <div className="row">
            <div className="col-6">
              <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                <li>
                  <div className="ttm-list-li-content">{t("genel")}</div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("altyapi")}</div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("network")} </div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("osi")} </div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("raid")}</div>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                <li>
                  <div className="ttm-list-li-content">
                    {t("program_diller")}
                  </div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("database")}</div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("devops")}</div>
                </li>
                <li>
                  <div className="ttm-list-li-content">{t("qa")}</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
          <b>{t("training_text_rest_1")}</b>
          <p>{t("training_text_rest_2")} </p>
        </div>
      </Modal>
    </>
  );
};
