import React, { Component, useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { languageConst } from "../../constants/language";
import { useTranslation } from "react-i18next";

const Mobile_menu = () => {
  const [collapse1, setCollapse1] = useState(false);
  const selected = localStorage.getItem("i18nextLng") || "en-US";
  const other = selected === "tr-TR" ? "en-US" : "tr-TR";
  const { img, url, alt } = languageConst[other];
  const { t } = useTranslation();
  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler
          id="hamburger1"
          onClick={() => setCollapse1(!collapse1)}
        />
        <MDBCollapse isOpen={collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <div className="dropdown">
                <Link
                  to="root"
                  href=""
                  smooth={true}
                  offset={-70}
                  duration={100}
                  className="nav-link"
                >
                  {t("home")}
                </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="dropdown">
                <Link
                  to="aboutus"
                  href=""
                  smooth={true}
                  offset={-30}
                  duration={100}
                  className="nav-link"
                >
                  {t("about_us_main_header")}
                </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="dropdown">
                <Link
                  to="service"
                  href=""
                  smooth={true}
                  offset={-30}
                  duration={100}
                  className="nav-link"
                >
                  {t("services_main_header")}
                </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="dropdown">
                <Link
                  to="apply"
                  href=""
                  smooth={true}
                  offset={-100}
                  duration={100}
                  className="nav-link"
                >
                  {t("apply_now_main_header")}
                </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="dropdown">
                <Link
                  to="contact"
                  href=""
                  smooth={true}
                  offset={-40}
                  duration={100}
                  className="nav-link"
                >
                  {t("contact_us_main_header")}
                </Link>
              </div>
            </MDBNavItem>
            <MDBNavItem>
              <div className="dropdown">
                <a href={url} className="nav-link">
                  <img src={img} alt={alt} width={25} height={25} />
                </a>
              </div>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </Router>
  );
};

export default Mobile_menu;
