import React, { Component } from "react";
import Slider from "react-slick";
import { Link, animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  var slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t } = useTranslation();

  return (
    <Slider
      className="slick_slider banner_slider banner_slider_2"
      {...slick_slider}
      slidesToShow={1}
      autoplay={false}
    >
      <div className="slide">
        <div
          className="slide_img"
          style={{
            backgroundImage: "url(images/mainpage.jpg)",
          }}
        ></div>
        <div className="slide__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="slide__content--headings ttm-textcolor-white text-center">
                  <h3 data-animation="animate__fadeInDown">
                    {t("better_together")}
                  </h3>
                  <div className="d-flex align-items-center justify-content-center">
                    <h2 data-animation="animate__fadeInDown">
                      {t("recruitment_and_job_consultancy")}
                    </h2>
                  </div>
                  <div className="mt-20 mb-40 mb-md-15">
                    <div className="row">
                      <div className="col-xl-7 col-lg-8 col-md-10 mx-auto">
                        <p
                          data-animation="animate__fadeInDown"
                          className="d-none d-md-block"
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div data-animation="animate__fadeInUp" data-delay="1.4">
                    <Link
                      to="apply"
                      href=""
                      smooth={true}
                      offset={-100}
                      duration={100}
                      className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                    >
                      {t("find_your_dream_job")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Banner;
