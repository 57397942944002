export const languageConst = {
  "en-US": {
    label: "English",
    img: "images/uk.png",
    dir: "ltr",
    alt: "lang_en",
    active: false,
    url: "/en",
  },
  "tr-TR": {
    label: "Türkçe",
    img: "images/tr.png",
    alt: "lang_tr",
    dir: "ltr",
    active: true,
    url: "/",
  },
};
