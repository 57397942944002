import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/vendor/flaticon.css";
import "./css/vendor/fontawesome.min.css";
import "./css/vendor/themify-icons.css";
import Home2 from "./pages/Home2";
import Error_404 from "./pages/Error_404";
import ScrollToTop from "./components/layout/Gotop";
import i18next from "i18next";

function App() {
  useEffect(() => {
    const domain = window.location.pathname.split("/")[1];
    if (domain === "en") {
      i18next.changeLanguage("en-US");
    } else {
      i18next.changeLanguage("tr-TR");
    }
  });

  return (
    <div className="page">
      <Router>
        <Switch>
          <Route exact path={`${"/"}`} component={Home2} />
          <Route exact path={`${"/en"}`} component={Home2} />
          <Route component={Error_404} />
          <ScrollToTop />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
