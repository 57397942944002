import React, { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <a className="home-link" href={"/"} title="Talent Works" rel="home">
        <img
          id="logo-img"
          height="42"
          width="170"
          className="img-fluid auto_size"
          src="images/talent-works-logo.png"
          alt="talent-works-logo"
        />
      </a>
    );
  }
}

export default Logo;
