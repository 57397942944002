import React, { Component } from "react";
import Menu from "./Menu";
import Mobile_menu from "./Mobile_menu";
import Logo from "./Logo";
import Header_search from "./Header_search";
import LanguageSelect from "./LanguageSelect";

export class Header extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.isSticky);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isSticky);
  }

  isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-Sticky")
      : header.classList.remove("is-Sticky");
  };

  render() {
    return (
      <header id="masthead" className="header ttm-header-style-03">
        {/* topbar */}

        {/* topbar end */}
        {/* site-header-menu */}
        <div id="site-header-menu" className="site-header-menu border-top">
          <div className="site-header-menu-inner ttm-stickable-header">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {/* site-navigation */}
                  <div className="site-navigation d-flex align-items-center justify-content-between">
                    {/* site-branding */}
                    <div className="site-branding">
                      <Logo />
                    </div>
                    {/* site-branding end */}
                    <div className="border-box-block ms-auto mr-20">
                      <div className=" d-flex align-items-center justify-content-between">
                        {/* menu */}
                        <Menu />
                        <div className="mobile-menu">
                          <Mobile_menu />
                        </div>
                        {/* menu end */}
                      </div>
                    </div>
                    {/* <div className="border-box-block ms-auto mr-20">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div className="header_btn">
                          <LanguageSelect></LanguageSelect>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* site-navigation end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* site-header-menu end */}
      </header>
    );
  }
}

export default Header;
