import React, { Component, useState } from "react";
import Slider from "react-slick";
import Header from "../components/layout/Header3";
import { Banner } from "../components/banner/Home2_banner";
import { Footer } from "../components/layout/Footer";
import { EgitimModal } from "../components/modal/Egitim";
import { Outsource } from "../components/modal/Outsource";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const iframe =
  '<iframe name="dummyframe" id="dummyframe"style="display: none;"></iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

export const Home2 = () => {
  const { t } = useTranslation();

  const success = () => {
    Swal.fire(t("basarili"), t("message"), "success");
  };

  var slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    slidesToShow: 4,
    rows: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="site-main">
      <Header />

      {/* Banner */}
      <Banner />
      {/* Banner end */}

      {/* about-section */}
      <section id="aboutus" className="ttm-row about-section clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">{t("about_us_header")}</h2>
                </div>
                <div className="title-desc">
                  <p>{t("about_us_header_text")}</p>
                </div>
              </div>
              {/* section title end */}
              <div className="mb-35">
                <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                  <li>
                    <i className="far fa-check-circle"></i>
                    <div className="ttm-list-li-content">
                      {t("about_us_header_text_1")}
                    </div>
                  </li>
                  <li>
                    <i className="far fa-check-circle"></i>
                    <div className="ttm-list-li-content">
                      {t("about_us_header_text_2")}
                    </div>
                  </li>
                  <li>
                    <i className="far fa-check-circle"></i>
                    <div className="ttm-list-li-content">
                      {t("about_us_header_text_3")}
                    </div>
                  </li>
                  <li>
                    <i className="far fa-check-circle"></i>
                    <div className="ttm-list-li-content">
                      {t("about_us_header_text_4")}
                    </div>
                  </li>
                  <li>
                    <i className="far fa-check-circle"></i>
                    <div className="ttm-list-li-content">
                      {t("about_us_header_text_5")}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="title-desc">
                <p className="text-theme-DarkColor">
                  {t("about_us_text_rest_1")}
                </p>
                <p className="text-theme-DarkColor">
                  {t("about_us_text_rest_2")}
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-9 col-sm-10 col-12 mx-auto my-auto">
              <div
                style={{
                  backgroundImage: "url(images/about-us.jpeg)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "700px",
                }}
              ></div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* about-section end */}

      {/* team-section */}
      <section className="ttm-row team-section bg-theme-GreyColor clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              {/* section title */}
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title">{t("our_team")}</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          <div className="row" style={{ justifyContent: "space-evenly" }}>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-team style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src="images/bihter-ozyilmaz.png"
                    alt="bihter-ozyilmaz"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-media ttm-media-link">
                  <div className="media-block">
                    <ul className="social-icons list-inline">
                      <li>
                        <a
                          href={"mailto:bihter.ozyilmaz@talentworkshr.com"}
                          rel="noopener"
                          aria-label="mail"
                        >
                          <i className="ti ti-email"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://www.linkedin.com/in/bihter-balata-%C3%B6zy%C4%B1lmaz-310455109/"
                          }
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="linkedin"
                        >
                          <i className="ti ti-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Bihter Özyılmaz</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("bihter_ozyilmaz_title")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-team style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src="images/ruya-ulagan.png"
                    alt="ruya-ulagan"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-media ttm-media-link">
                  <div className="media-block">
                    <ul className="social-icons list-inline">
                      <li>
                        <a
                          href={"mailto:ruyau@talentworkshr.com"}
                          rel="noopener"
                          aria-label="mail"
                        >
                          <i className="ti ti-email"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://www.linkedin.com/in/r%C3%BCya-ulagan-a2299853/"
                          }
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="linkedin"
                        >
                          <i className="ti ti-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Rüya Ulagan</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("ruya_ulagan_title")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-evenly" }}>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-team style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src="images/gizem-olmez.png"
                    alt="gizem-olmez"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-media ttm-media-link">
                  <div className="media-block">
                    <ul className="social-icons list-inline">
                      <li>
                        <a
                          href={"mailto:gizem.olmez@talentworkshr.com"}
                          rel="noopener"
                          aria-label="mail"
                        >
                          <i className="ti ti-email"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://www.linkedin.com/in/gizem-%C3%B6lmez-a02a75170/"
                          }
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="linkedin"
                        >
                          <i className="ti ti-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Gizem Ölmez</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("ise_alim_title")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-4">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-team style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src="images/seyma-yildiz.png"
                    alt="seyma-yildiz"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-media ttm-media-link">
                  <div className="media-block">
                    <ul className="social-icons list-inline">
                      <li>
                        <a
                          href={"mailto:seyma.yildiz@talentworkshr.com"}
                          rel="noopener"
                          aria-label="mail"
                        >
                          <i className="ti ti-email"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://www.linkedin.com/in/%C5%9Feyma-y%C4%B1ld%C4%B1z-9b66501b2/"
                          }
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="linkedin"
                        >
                          <i className="ti ti-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Şeyma Yıldız</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("ise_alim_title")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
          </div>
        </div>
      </section>
      {/* team-section end */}
      {/* services-section */}
      <section
        id="service"
        className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
        style={{ backgroundImage: "url(images/bg-image/row-bgimage-1.png)" }}
      >
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-11">
              {/* section title */}
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h2 className="title">{t("services_header")}</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* Slider */}
          <Slider
            className="row slick_slider slick-arrows-style2 pt-20 mb_10"
            {...slick_slider}
            slidesToShow={2}
            rows={2}
            arrows={true}
            autoplay={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: { slidesToShow: 2, slidesToScroll: 2 },
              },
              {
                breakpoint: 575,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
              },
            ]}
          >
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style1">
                {/* featured-thumbnail */}
                <div className="col-md-6 pl-40 pt-40 image-container">
                  <img
                    src={"images/secme-yerlestirme.jpg"}
                    alt="secme-yerlestirme"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>{t("search_selection")}</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("search_selection_text")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style1">
                {/* featured-thumbnail */}
                <div className="col-md-6 pl-40 pt-40 image-container">
                  <img
                    className="img-fluid"
                    src="images/yetenek-yonetimi.jpg"
                    alt="yetenek-yonetimi"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>{t("talent_management_header")}</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("talent_management_text")}</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style1">
                {/* featured-thumbnail */}
                <div className="col-md-6 pl-40 pt-40 image-container">
                  <img
                    className="img-fluid"
                    src="images/Outsource.jpeg"
                    alt="outsource"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>{t("outsource")}</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("outsource_text")}</p>
                    <p>{t("outsource_text_2")}</p>

                    <Outsource />
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style1">
                {/* featured-thumbnail */}
                <div className="col-md-6 pl-40 pt-40 image-container">
                  <img
                    className="img-fluid"
                    src="images/Egitim.jpg"
                    alt="egitim"
                  />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>{t("training_header")}</h3>
                  </div>
                  <div className="featured-desc">
                    <p>{t("training_text")}</p>
                    <div>
                      <EgitimModal />
                    </div>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
          </Slider>
          {/* Slider end */}
        </div>
      </section>
      {/* services-section end */}
      {/* features-section end */}

      {/* about-section */}
      <section className="ttm-row about-section clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">{t("ise_alim_surec_baslik")}</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-4"></div>
            <div className="col-lg-6">
              <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                      <i className="ttm-num ti-info"></i>
                    </div>
                  </div>
                  <div className="featured-content ttm-bgcolor-grey">
                    <div className="featured-title">
                      <h3>{t("analiz_baslik")}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>{t("analiz_text")}</p>
                    </div>
                  </div>
                </div>
                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                      <i className="ttm-num ti-info"></i>
                    </div>
                  </div>
                  <div className="featured-content ttm-bgcolor-grey">
                    <div className="featured-title">
                      <h3>{t("ise_alim_metodoloji_baslik")}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>{t("ise_alim_metodoloji_text")}</p>
                    </div>
                  </div>
                </div>
                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                      <i className="ttm-num ti-info"></i>
                    </div>
                  </div>
                  <div className="featured-content ttm-bgcolor-grey">
                    <div className="featured-title">
                      <h3>{t("ise_alim_sureci_baslik")}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>{t("ise_alim_sureci_text")}</p>
                    </div>
                  </div>
                </div>
                <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                      <i className="ttm-num ti-info"></i>
                    </div>
                  </div>
                  <div className="featured-content ttm-bgcolor-grey">
                    <div className="featured-title">
                      <h3>{t("raporlama_baslik")}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>{t("raporlama_text")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10 col-11 m-auto">
              <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg spacing-2 z-index_1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content"></div>
                <Iframe iframe={iframe} />
              </div>
              <div className="ttm_single_image-wrapper">
                <img
                  className="img-fluid"
                  src="/images/single-img-03.jpeg"
                  alt="single_03"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact-section */}
      <section id="apply" className="ttm-row contact-section bg-theme clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title */}
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title">{t("apply_now_subtittle")}</h2>
                </div>
              </div>
              {/* section-title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-6">
              <div className="pr-30 pr-md-0 mt-15">
                <div className="ttm_single_image-wrapper mb-20">
                  <img
                    className="img-fluid w-100"
                    src="images/join-us.jpg"
                    alt="join-us"
                  />
                </div>

                {/*  featured-icon-box end */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor  p-40 p-lg-30 mt-15 mt-lg-30">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <p
                    className="mb-30 text-theme-DarkColor"
                    style={{ textAlign: "center" }}
                  >
                    {t("size_uygun")}
                  </p>
                  <form
                    className="contact_form wrap-form"
                    method="POST"
                    encType="multipart/form-data"
                    action="https://talent-work.herokuapp.com/form"
                    target="dummyframe"
                    onSubmit={success}
                  >
                    <div className="row ttm-boxes-spacing-15px">
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            name="name"
                            type="text"
                            placeholder={t("name")}
                            required="required"
                          />
                        </label>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            name="email"
                            type="text"
                            placeholder={t("e-mail")}
                            required="required"
                          />
                        </label>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            name="phone"
                            type="text"
                            placeholder={t("phone-number")}
                            required="required"
                          />
                        </label>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <div
                          style={{
                            border: "1px solid rgba(119, 119, 119, 0.2)",
                            padding: "0px 20px",
                            backgroundColor: "#fff",
                            borderRadius: "6px",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-3 mx-auto my-auto">
                              <p>{t("ozgecmis")}</p>
                            </div>
                            <div className="col-md-9 ">
                              <input
                                type="file"
                                id="uploadInput"
                                name="file"
                                aria-label="File browser example"
                                accept="application/pdf"
                                className="custom-file-input"
                                required
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <div className="cookies-checkbox mt-15">
                          <div className="d-flex flex-row justify-content-start">
                            <input
                              className="w-auto mr-10 mt-5"
                              id="cookies-consent"
                              name="cookies-consent"
                              type="checkbox"
                              required
                            />
                            <span
                              style={{ fontSize: "10px", lineHeight: "20px" }}
                            >
                              {t("aydinlatma_metni_1")}{" "}
                              <a
                                href={"file/kvkk.pdf"}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {t("clarification")}{" "}
                              </a>
                              {t("aydinlatma_metni_2")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                      type="submit"
                    >
                      {t("basvuru")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact-section end */}

      {/* grid-section */}
      <section
        id="contact"
        className="ttm-row grid-section bg-theme-GreyColor  clearfix"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title */}
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title"> {t("iletisim_altbaslik")}</h2>
                  {/* <p className="mb-30 text-theme-DarkColor">
                      Size uygun iş fırsatlarımız için mutlaka tanışalım!
                    </p> */}
                </div>
              </div>
              {/* section-title end */}
            </div>
          </div>
          {/* row */}
          <div className="row justify-content-center align-self-center">
            <div className="col-lg-6 col-md-6">
              <div className="featured-imagebox box-shadow">
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src="images/location.PNG"
                    alt="location"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5  col-md-5 mx-auto my-auto">
              <div className="d-flex align-items-center">
                <div
                  className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                >
                  <i className="fa fa-phone"></i>
                </div>
                <div className="pl-15">
                  <h6 className="mb-5">{t("phone")}</h6>
                  <p className="featured-desc mb-0">+90 (212) 401 41 16</p>
                </div>
              </div>

              <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

              <div className="d-flex align-items-center">
                <div
                  className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                >
                  <i className="fa fa-globe"></i>
                </div>
                <div className="pl-15">
                  <h6 className="mb-5">{t("e-mail")}</h6>
                  <p className="featured-desc mb-0">
                    <a href="mailto:info@talentworkshr.com">
                      info@talentworkshr.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

              <div className="d-flex align-items-center">
                <div
                  className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                >
                  <i className="fa fa-home"></i>
                </div>
                <div className="pl-15">
                  <h6 className="mb-5">{t("location")}</h6>
                  <p className="featured-desc mb-0">
                    Esentepe Mah. Büyükdere Cad.
                  </p>
                  <p className="featured-desc mb-0">
                    No: 199/6 Levent 199 Binası, Kat:-1
                  </p>
                  <p className="featured-desc mb-0">Levent, Şişli/İstanbul</p>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* grid-section end */}

      <Footer />
    </div>
  );
};

export default Home2;
