import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    background: "white",
    width: "70%",
    height: "85%",
    inset: "",
    overflowY: "auto",
    position: "relative",
    border: "1px solid #ccc",
    borderRadius: "10px",
  },
};

const buttonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
};

export const Outsource = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <button
        className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
        onClick={() => setIsOpen(true)}
      >
        {"> "}
        {t("details")}
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById("site-main")}
      >
        <button onClick={() => setIsOpen(false)} style={buttonStyles}>
          X
        </button>
        <div className="ttm-service-description mt-20">
          <h3 className="mb-20">{t("outsource")}</h3>

          <h5>{t("outsource_popup_subtittle")}</h5>

          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

          <b>{t("time_cost_header")}</b>
          <p>{t("time_cost_text")}</p>

          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

          <b>{t("legal_risk_header")}</b>
          <p>{t("legal_risk_text")}</p>

          <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>

          <b>{t("experts_header")}</b>
          <p>{t("experts_text")}</p>
        </div>
      </Modal>
    </>
  );
};
