import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { languageConst } from "../../constants/language";

export const Menu = () => {
  const selected = localStorage.getItem("i18nextLng") || "en-US";
  const other = selected === "tr-TR" ? "en-US" : "tr-TR";
  const { img, url, alt } = languageConst[other];
  const { t } = useTranslation();

  return (
    <Router>
      <nav className="main-menu">
        <ul className="menu">
          <li className="mega-menu-item">
            <Link
              to="root"
              href=""
              smooth={true}
              offset={-70}
              duration={100}
              className="mega-menu-link"
            >
              {t("home")}
            </Link>
          </li>
          <li className="mega-menu-item">
            <Link
              to="aboutus"
              href=""
              smooth={true}
              offset={-30}
              duration={100}
              className="mega-menu-link"
            >
              {t("about_us_main_header")}
            </Link>
          </li>
          <li className="mega-menu-item">
            <Link
              to="service"
              href=""
              smooth={true}
              offset={-30}
              duration={100}
              className="mega-menu-link"
            >
              {t("services_main_header")}
            </Link>
          </li>
          <li className="mega-menu-item">
            <Link
              to="apply"
              href=""
              smooth={true}
              offset={-20}
              duration={100}
              className="mega-menu-link"
            >
              {t("apply_now_main_header")}
            </Link>
          </li>
          <li className="mega-menu-item">
            <Link
              to="contact"
              href=""
              smooth={true}
              offset={-40}
              duration={100}
              className="mega-menu-link"
            >
              {t("contact_us_main_header")}
            </Link>
          </li>
          <li>
            <div className="dropdown">
              <a href={url} className="nav-link">
                <img src={img} alt={alt} width={25} height={25} />
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </Router>
  );
};

export default Menu;
