import React, { Component } from "react";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
      <div className="second-footer">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
              <div className="widget widget_text clearfix">
                <div className="footer-logo">
                  <img
                    id="footer-logo-img"
                    className="img-fluid auto_size "
                    height="40"
                    width="142"
                    src="images/talent-works-logo.png"
                    alt="footer-logo"
                  />
                  <img
                    id="footer-logo-img"
                    className="img-fluid auto_size ml-100"
                    height="80"
                    width="80"
                    src="images/iskurlogo.png"
                    alt="footer-logo"
                  />
                </div>
                <p style={{ fontSize: "11px", lineHeight: 2 }}>
                  {t("sol_alt_text_iskur_1")}
                  <br />
                  <br />
                  {t("sol_alt_text_iskur_2")}
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area ">
              <div className="widget widget-contact clearfix ">
                <h3 className="widget-title">{t("kolay_iletisim_baslik")}</h3>
                <ul className="widget_contact_wrapper">
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                    <h3>{t("bizi_arayin_baslik")}:</h3>+90 (212) 401 41 16
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                    <h3>{t("adres_baslik")}:</h3>Esentepe Mah. Büyükdere Cad.
                    No: 199/6 Levent 199 Binası, Kat:-1 Levent, Şişli/İstanbul
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-email"></i>
                    <h3>{t("e-mail")}:</h3>
                    <a href="mailto:info@talentworkshr.com">
                      info@talentworkshr.com
                    </a>
                  </li>
                  <li style={{ padding: "0px" }}>
                    <ul
                      className="social-icons list-inline"
                      style={{ textAlign: "center" }}
                    >
                      <li className="social-media">
                        <a
                          href="https://www.linkedin.com/company/talentworkshr"
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="linkedin"
                        >
                          <i
                            className="ti ti-linkedin"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </a>
                      </li>
                      <li className="social-media">
                        <a
                          href="https://twitter.com/talentworkshr"
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="twitter"
                        >
                          <i
                            className="ti ti-twitter-alt"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </a>
                      </li>
                      <li className="social-media">
                        <a
                          href="mailto:info@talentworkshr.com"
                          aria-label="email"
                        >
                          <i
                            className="ti ti-email"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </a>
                      </li>
                      <li className="social-media">
                        <a
                          href="https://www.instagram.com/talentworks_hr/"
                          target={"_blank"}
                          rel="noreferrer"
                          aria-label="instagram"
                        >
                          <i
                            className="ti ti-instagram"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <span className="copyright">
                Copyright © {new Date().getFullYear()}
                <a href={"/"}> Talent Works </a> {t("all_rights_reserved")}
              </span>
              <ul className="footer-nav-menu">
                <li>
                  <a href={"file/kvkk.pdf"} target={"_blank"} rel="noreferrer">
                    {t("kvkk_baslik")}
                  </a>
                </li>
                <li>
                  <a
                    href={"file/acik_riza_metni.pdf"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {t("riza_beyan")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
